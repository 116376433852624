













































import { Component, Prop, Mixins } from 'vue-property-decorator'

import Card from '@/components/cards/Card.vue'
import NotifyMixin from '@/mixins/NotifyMixin'
import { formatCost, parseDate } from '@/utils/functions'

@Component({
  components: {
    Card,
  },
})


export default class BillCard extends Mixins(NotifyMixin){
  @Prop({ required: true })
  private bill!: any

  private get isOverdue (): boolean {
    return new Date(this.bill.finishDate).getTime() < new Date().getTime()
  }

  private get progressToPay (): number {
    const overallTimeToPay = new Date(this.bill.finishDate).getTime() - new Date(this.bill.startDate).getTime()
    const timeToPay = new Date(this.bill.finishDate).getTime() - new Date().getTime()

    return Math.ceil(timeToPay / overallTimeToPay * 100)
  }

  private get daysBetween (): number {
    return Math.floor((new Date(this.bill.finishDate).getTime() - new Date().getTime()) / (1000*60*60*24))
  }

  private get daysAfter (): number {
    return Math.floor((new Date().getTime() - new Date(this.bill.finishDate).getTime() ) / (1000*60*60*24))
  }

  private parseDate = parseDate
  private formatCost = formatCost
}
