










































// CORE
import { Component, Mixins } from 'vue-property-decorator'

// MIXINS
import SystemMixin from '@/mixins/SystemMixin'

// LIBRARIES
import lodash from 'lodash'

// COMPONENTS
import NotifyMixin from '@/mixins/NotifyMixin'
import DefaultLayout from '@/components/layouts/DefaultLayout.vue'
import CaptionCard from '@/components/cards/CaptionCard.vue'
import BillCard from '@/components/cards/BillCard.vue'

//STORE
import BillsModule from '@/store/modules/fleet/bills'
import { InvoiceResource } from '@/store/types'

@Component({
  components: {
    BillCard,
    DefaultLayout,
    CaptionCard,
  },
})
export default class BillsLayout extends Mixins(NotifyMixin, SystemMixin) {
  private get statuses () {
    return BillsModule.statuses
  }

  private get activeStatus (): number | string | undefined {
    return BillsModule.statusId
  }

  private set activeStatus (value: number | string | undefined) {
    BillsModule.setStatusId(value)
  }

  private get billsFilter () {
    return BillsModule.billsFilter
  }

  private set billsFilter (val: any){
    BillsModule.setBillsFilter(val)
  }

  private get bills () {
    let bills = []
    switch (this.activeStatus) {
    case 'available': {
      bills = BillsModule.bills.filter((item: InvoiceResource) => new Date(item.finishDate).getTime() > new Date().getTime())
      break
    }
    case 'lateToPay': {
      bills = BillsModule.bills.filter((item: InvoiceResource) => new Date(item.finishDate).getTime() < new Date().getTime())
      break
    }
    default: {
      bills = BillsModule.bills
      break
    }
    }
    return bills
  }

  private get sumToPay (){
    const sum = lodash.sum(this.bills.map(item => +item.amount))

    return Intl.NumberFormat('ru').format(sum)
  }

  private created () {
    // BillsModule.getBills(+this.entity)
    // BillsModule.getDictionaries(+this.entity)
  }
}
